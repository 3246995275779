import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ConnectWithUsComponent from "../components/connect-with-us/connect-with-us.component"

const ConnectWithUs = () => {
  return (
    <Layout>
      <ConnectWithUsComponent />
    </Layout>
  )
}

export default ConnectWithUs

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
