import React from "react"
import {useTranslation} from "react-i18next"

import './connect-with-us.component.less';
import Seo from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const ConnectWithUsComponent = () => {
    const { i18n, t } = useTranslation();
    const { originalPath } = useI18next();

    return (
        <div>
            <Seo 
                description={t("metaDescriptionConnectWithUs")} 
                lang={i18n.language} 
                title={t("titleConnectWithUs")} 
            />
            <div className="connect-with-us wrapper_block wrapper_block__block">

                <div className="connect-with-us__block">
                    <div className="connect-with-us__title">{t("forAnyQueries")}</div>
                    <div className="connect-with-us__mail connect-with-us__mail--purple">
                        <a href="mailto:hello@airvoice.global">{t("mail1")}</a>
                    </div>
                </div>

                <div className="connect-with-us__block">
                    <div className="connect-with-us__title">For any Queries related to India</div>
                    <div className="connect-with-us__mail">
                        <a href="mailto:namaste@airvoice.global">namaste@airvoice.global</a>
                    </div>
                </div>

            </div>
        </div>)
}

ConnectWithUsComponent.propTypes = {}

ConnectWithUsComponent.defaultProps = {}

export default ConnectWithUsComponent
